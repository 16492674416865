import { LibraryContext } from 'contextNew/Library';
import PropTypes from 'prop-types';
import { Component, createContext } from 'react';

const CMSContext = createContext();
const { Consumer: CMSConsumer, Provider } = CMSContext;

class CMSProvider extends Component {
  state = {
    breadcrumbs: [],
    childTitles: [],
    libraryPath: '',
    loading: false,
    navigationPath: '',
    siblingTitles: [],
    screenWidth: 1100,
  };

  static contextType = LibraryContext;

  componentDidMount() {
    const { url } = this.props;
    const id = url[0] === '/group-library/' ? url : `/${url}`;
    this.updateBreadcrumb(id, []);
  }

  update = ({ navigationPath, libraryPath = '', depth, loading = false }) => {
    this.setState({
      navigationPath,
      libraryPath,
      loading,
      depth,
    });
  };

  onLoading = (loading) => this.setState({ loading });

  updateBreadcrumb = (pathname, breadcrumbs) => {
    this.setState({
      breadcrumbs: [...this.getActiveDepartments(pathname), ...breadcrumbs],
    });
  };

  updateSiblings = ({ childTitles, siblingTitles }) =>
    this.setState({ childTitles, siblingTitles });

  getActiveDepartments = (pathname) => {
    return this.context.rootNodes
      .filter((d) => pathname.includes(d.path))
      .map((d) => ({ title: d.title, link: d.path, type: 'department' }));
  };

  setLibraryLocation = (navigationPath) => {
    this.setState({
      navigationPath,
      libraryPath: navigationPath,
    });
  };

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          loading: this.context.isRootNodesLoading || this.state.loading,
          update: this.update,
          onLoading: this.onLoading,
          setLibraryLocation: this.setLibraryLocation,
          updateBreadcrumb: this.updateBreadcrumb,
          updateSiblings: this.updateSiblings,
          screenWidth: this.props.screenWidth,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

CMSProvider.propTypes = {
  url: PropTypes.string.isRequired,
  search: PropTypes.string,
  screenWidth: PropTypes.number,
};

export { CMSContext };
export default CMSProvider;
export { CMSConsumer };
