export enum HeroBannerSize {
  Medium = 'medium',
  Large = 'large',
}

export type HeroBannerCreate = {
  size: HeroBannerSize;
  title: string;
  description?: string;
  linkUrl?: string;
  linkButtonText?: string;
  heroImage?: string;
  heroAltText?: string;
  publishedDate: string;
  publishedInDivisions?: { id: string; name: string }[];
  overrideDivisionalMessage?: boolean;
};

export type HeroBannerEdit = { id: string } & HeroBannerCreate;

export type HeroBannerGetResponseData = {
  _doc: string;
  size?: HeroBannerSize;
  title: string;
  description?: string;
  linkUrl?: string;
  linkButtonText?: string;
  heroImage?: string;
  heroAltText?: string;
  publishedDate?: string;
  publishedInDivisions?: { id: string; name: string }[];
  overrideDivisionalMessage?: boolean;
};
