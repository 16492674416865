import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { ProductOfTheWeekType } from 'api/cms/ProductOfTheWeek/types';
import { Chip, LikeButton, TypographyWithMaxLines } from 'componentsNew';
import { useMemo } from 'react';
import settings from 'settings';
import { getDateOrDaysAgo } from 'utils/misc/time';

const { cdnTokenKey } = settings.misc;
const { cms } = settings.cmsBaseImagePath;

type ProductOfTheWeekCardProps = {
  id?: string;
  sx?: SxProps<Theme>;
  view?: 'home' | 'gallery';
  product: ProductOfTheWeekType;
  likesMeta?: {
    likesCount: number;
    isLikedByMe: boolean;
  };
  onClick: () => void;
  onLike: () => void;
};

const ProductOfTheWeekCard = ({
  id,
  sx,
  view = 'home',
  product,
  likesMeta,
  onClick,
  onLike,
}: ProductOfTheWeekCardProps) => {
  const elementId = id || product._doc;

  const isPublished = useMemo(() => {
    if (!product?.startDate) {
      return false;
    }
    const now = new Date();
    const startDate = new Date(product.startDate);
    return startDate.getTime() < now.getTime();
  }, [product.startDate]);

  return (
    <Stack
      id={elementId}
      sx={[
        (theme) => ({
          ...(!isPublished && { filter: 'grayscale(100%)', opacity: 0.5 }),
          border: `1px solid ${theme.colors.border.surfacePrimary}`,
          borderRadius: theme.border.radius.md,
          overflow: 'hidden',
          [`&:has(#${elementId}-image:hover), &:has(#${elementId}-title:hover), &:has(#${elementId}-preamble:hover)`]:
            {
              border: `1px solid ${theme.colors.border.brandBase}`,
              [`#${elementId}-title > h3`]: {
                textDecoration: 'underline',
                textDecorationColor: theme.colors.text.actionHover,
                color: theme.colors.text.actionHover,
              },
              [`#${elementId}-preamble > p`]: {
                textDecoration: 'underline',
                textDecorationColor: theme.colors.text.tertiary,
                color: theme.colors.text.tertiary,
              },
            },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Link
        id={`${elementId}-image`}
        component="button"
        tabIndex={-1}
        sx={(theme) => ({
          width: '100%',
          aspectRatio: 1.1,
          backgroundColor: theme.colors.surface.informative,
        })}
        onClick={onClick}
      >
        {product.heroImage && (
          <Box
            component="img"
            src={`${cms}${product.heroImage}/480?${
              cdnTokenKey ? cdnTokenKey : ''
            }`}
            alt={product.heroAltText || ''}
            title={product.heroAltText || ''}
            sx={{
              display: 'block',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
      </Link>
      <Stack
        sx={(theme) => ({
          gap: theme.spacing('xxxs'),
          padding: theme.spacing('sm'),
          flexGrow: 1,
        })}
      >
        <Link
          id={`${elementId}-title`}
          component="button"
          onClick={onClick}
          sx={(theme) => ({
            textAlign: 'left',
            textDecoration: 'unset',
            color: theme.colors.text.secondary,
            '&:hover': {
              textDecoration: 'underline',
              textDecorationColor: theme.colors.text.actionHover,
              color: theme.colors.text.actionHover,
            },
          })}
        >
          <TypographyWithMaxLines
            maxLines={2}
            component="h3"
            variant="body1"
            sx={{ fontWeight: 'bold' }}
          >
            {product.title}
          </TypographyWithMaxLines>
        </Link>
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: theme.spacing('xs'),
            flexGrow: 1,
          })}
        >
          {view === 'home' && product.preamble && (
            <Link
              id={`${elementId}-preamble`}
              component="button"
              tabIndex={-1}
              onClick={onClick}
              sx={(theme) => ({
                textAlign: 'left',
                textDecoration: 'unset',
                color: theme.colors.text.tertiary,
                '&:hover': {
                  textDecoration: 'underline',
                  textDecorationColor: theme.colors.text.tertiary,
                  color: theme.colors.text.tertiary,
                },
              })}
            >
              <TypographyWithMaxLines variant="body2" maxLines={2}>
                {product.preamble}
              </TypographyWithMaxLines>
            </Link>
          )}
          {view === 'gallery' && (
            <Stack
              sx={(theme) => ({
                alignItems: 'baseline',
                width: 'calc(100% - 3rem)',
                gap: theme.spacing('xxs'),
              })}
            >
              {product?.startDate && (
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.colors.text.secondary,
                  })}
                >
                  {getDateOrDaysAgo(product.startDate)}
                </Typography>
              )}
              {product?.division && (
                <Chip
                  size="small"
                  variant="outlined"
                  color="default"
                  label={product.division}
                />
              )}
            </Stack>
          )}
          {likesMeta && (
            <LikeButton
              id={`${elementId}-like`}
              isLikedByMe={likesMeta.isLikedByMe}
              likesCount={likesMeta.likesCount}
              onClick={onLike}
              sx={{ alignSelf: 'end', marginLeft: 'auto' }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export { ProductOfTheWeekCard };
