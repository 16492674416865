import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAIChat } from 'components/Context/AIChat';
import { Icon } from 'componentsNew';
import { ReactComponent as RobotIcon } from 'componentsNew/Icon/featured/Robot.svg';
import { useCallback, useEffect, useState } from 'react';
import { translations } from 'translations';
import { GAonAIChatIconClick } from 'utils/analytics';

const elementId = 'ai-chat-button';

const LOCAL_STORAGE_KEY_HINT_DISABLED = 'ai-chat-hint-disabled';

const AIChatButton = () => {
  const [hintOpen, setHintOpen] = useState<boolean>(false);

  const { setChatOpen } = useAIChat();

  useEffect(() => {
    const disabled = localStorage.getItem(LOCAL_STORAGE_KEY_HINT_DISABLED);
    if (disabled) return;
    setHintOpen(true);
  }, []);

  const disableHint = useCallback(() => {
    if (!hintOpen) return;
    setHintOpen(false);
    localStorage.setItem(LOCAL_STORAGE_KEY_HINT_DISABLED, 'true');
  }, [hintOpen]);

  return (
    <Box sx={() => ({ position: 'relative' })}>
      <IconButton
        id={elementId}
        aria-label={translations.aiChatButton}
        sx={(theme) => ({
          padding: 0.25,
          backgroundColor: theme.colors.surface.secondary,
          '&:hover': { backgroundColor: theme.colors.surface.highlight },
        })}
        onClick={() => {
          disableHint();
          GAonAIChatIconClick();
          setChatOpen(true);
        }}
      >
        <RobotIcon />
      </IconButton>
      {hintOpen && (
        <Stack
          id={`${elementId}-hint`}
          sx={(theme) => ({
            flexDirection: 'row',
            alignItems: 'start',
            position: 'absolute',
            top: 'calc(100% + 0.5rem)',
            left: { xs: '-1.5rem', md: '-1rem' },
            color: theme.colors.text.inversePrimary,
            backgroundColor: theme.colors.surface.inversePrimary,
            borderRadius: theme.border.radius.sm,
            width: '6rem',
            // "zIndex: 11" is due to old header/navigation styles in scss files
            zIndex: 11,
            '&::after': {
              position: 'absolute',
              top: '-1rem',
              left: { xs: '2.75rem', md: '2.25rem' },
              width: 0,
              height: 0,
              marginLeft: '-0.5rem',
              border: 'solid transparent',
              borderWidth: '0.5rem',
              borderColor: 'transparent',
              borderBottomColor: theme.colors.surface.inversePrimary,
              content: '""',
            },
          })}
        >
          <Typography
            variant="caption"
            sx={(theme) => ({
              padding: `${theme.spacing('xxxs')} ${theme.spacing('xxs')}`,
            })}
          >
            {translations.aiChatButtonHint}
          </Typography>
          <IconButton
            size="small"
            aria-label={translations.close}
            onClick={disableHint}
          >
            <Icon type="xMark" color="inversePrimary" size={16} />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
};

export { AIChatButton };
