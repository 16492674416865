import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { GetContentManagementLibraryArticlesResponse } from 'api/cms/contentManagement/types';
import * as feedbackApi from 'api/feedback';
import { useUser } from 'components/Context/User';
import { AlertBar, TablePagination } from 'componentsNew';
import { AlertBarProps } from 'componentsNew/AlertBar';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';
import { RequestStatus } from 'types';

import * as helpers from './helpers';
import { ManageLibraryActions } from './ManageLibraryActions';
import { ArticleStatus, ManageLibraryFilter } from './ManageLibraryFilter';
import {
  ManageLibraryTable,
  ManageLibraryTableField,
  ManageLibraryTableItem,
  SortOrder,
} from './ManageLibraryTable';

const elementId = 'profile-manage-library';

const INITIAL_FILTER = {
  isAdmin: false,
  articleStatus: ArticleStatus.Published,
};
const INITIAL_SORT = {
  field: ManageLibraryTableField.ExpiryDate,
  order: SortOrder.Asc,
};
const INITIAL_PAGINATION = {
  page: 1,
  rowsPerPage: 10,
};
const INITIAL_SELECTION = {
  byId: {},
  isAllSelected: false,
  isSomeSelected: false,
};

const ManageLibrary = () => {
  const [filter, setFilter] = useState<{
    isAdmin: boolean;
    articleStatus: ArticleStatus;
  }>(INITIAL_FILTER);

  const [sort, setSort] = useState<{
    field: ManageLibraryTableField;
    order: SortOrder;
  }>(INITIAL_SORT);

  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>(INITIAL_PAGINATION);

  const [selection, setSelection] = useState<{
    byId: Record<string, boolean>;
    isAllSelected: boolean;
    isSomeSelected: boolean;
  }>(INITIAL_SELECTION);

  const [tableContent, setTableContent] = useState<{
    items: ManageLibraryTableItem[];
    total: number;
  }>({ items: [], total: 0 });

  const [fetchArticlesStatus, setFetchArticlesStatus] = useState<RequestStatus>(
    RequestStatus.Idle
  );

  const [libraryActionsAlert, setLibraryActionsAlert] = useState<{
    open: boolean;
    type: AlertBarProps['type'];
    text: string;
  }>({ open: false, type: 'success', text: '' });

  const user = useUser();
  const mountedRef = useRef<boolean>(true);

  const fetchArticles = useCallback(
    async (
      filter: { isAdmin: boolean; articleStatus: ArticleStatus },
      sort: { field: ManageLibraryTableField; order: SortOrder },
      pagination: { page: number; rowsPerPage: number }
    ) => {
      let items: ManageLibraryTableItem[] = [];
      let total = 0;

      const fetchArticlesRequest = helpers.getFetchArticlesRequest(
        filter,
        sort,
        pagination
      );
      if (!fetchArticlesRequest) {
        return { items, total };
      }
      try {
        setFetchArticlesStatus(RequestStatus.Loading);
        const response = (await fetchArticlesRequest.request(
          fetchArticlesRequest.queryParams
        )) as GetContentManagementLibraryArticlesResponse;

        total = response.data.data.totalRows;
        items = response.data.data.articles.map((cmsArticle) => {
          const item: ManageLibraryTableItem = {
            id: cmsArticle.id,
            title: cmsArticle.title,
            expiryDate: cmsArticle.expiryDate,
            deletionDate: cmsArticle.deletionDate,
            hasSubPages: cmsArticle.hasSubPages,
            brokenLinksCount: cmsArticle.brokenLinksCount,
            modifiedDate: cmsArticle.modifiedDate,
            informationOwner: cmsArticle.informationOwner,
            linkPath: `/library-redirect/${cmsArticle.id}`,
          };
          return item;
        });
      } catch {
        if (mountedRef.current) {
          setFetchArticlesStatus(RequestStatus.Failure);
        }
        return { items, total };
      }
      if (filter.articleStatus !== ArticleStatus.Published || !items.length) {
        if (mountedRef.current) {
          setFetchArticlesStatus(RequestStatus.Idle);
        }
        return { items, total };
      }
      try {
        const articleIds = items.map((item) => item.id);
        const response = await feedbackApi.getFeedbackList(articleIds);

        const feedbackCountByArticleId =
          helpers.getFeedbackCountByArticleId(response);

        items = items.map((item) => {
          const feedbackCount = feedbackCountByArticleId[item.id];
          if (!feedbackCount) {
            return item;
          }
          return {
            ...item,
            feedback: {
              helpfulCount: feedbackCount.helpfulCount,
              notHelpfulCount: feedbackCount.notHelpfulCount,
              neutralCount: feedbackCount.neutralCount,
            },
          };
        });
      } catch {
        if (mountedRef.current) {
          setFetchArticlesStatus(RequestStatus.Failure);
        }
        return { items, total };
      }
      if (mountedRef.current) {
        setFetchArticlesStatus(RequestStatus.Idle);
      }
      return { items, total };
    },
    []
  );

  const handleFilterChange = useCallback(
    async (newFilter: { isAdmin: boolean; articleStatus: ArticleStatus }) => {
      let newSort = INITIAL_SORT;

      switch (newFilter.articleStatus) {
        case ArticleStatus.Published:
          newSort = {
            field: ManageLibraryTableField.ExpiryDate,
            order: SortOrder.Asc,
          };
          break;
        case ArticleStatus.Unpublished:
          newSort = {
            field: ManageLibraryTableField.DeletionDate,
            order: SortOrder.Asc,
          };
          break;
        case ArticleStatus.Drafts:
          newSort = {
            field: ManageLibraryTableField.ModifiedDate,
            order: SortOrder.Asc,
          };
          break;
        case ArticleStatus.Ownerless:
          newSort = {
            field: ManageLibraryTableField.ExpiryDate,
            order: SortOrder.Asc,
          };
          break;
      }
      const newPagination = {
        ...pagination,
        page: 1,
      };
      setFilter(newFilter);
      setSort(newSort);
      setPagination(newPagination);
      setSelection(INITIAL_SELECTION);
      const newTableContent = await fetchArticles(
        newFilter,
        newSort,
        newPagination
      );
      setTableContent(newTableContent);
    },
    [pagination, fetchArticles]
  );

  const handleSortChange = useCallback(
    async (newSort: { field: ManageLibraryTableField; order: SortOrder }) => {
      setSort(newSort);

      if (tableContent.items.length === tableContent.total) {
        const newTableContent = {
          ...tableContent,
          items: helpers.sortTableItems(
            tableContent.items,
            newSort.field,
            newSort.order
          ),
        };
        setTableContent(newTableContent);
        return;
      }
      const newPagination = { ...pagination, page: 1 };
      const newTableContent = await fetchArticles(
        filter,
        newSort,
        newPagination
      );
      setTableContent(newTableContent);
      setPagination(newPagination);
      setSelection(INITIAL_SELECTION);
    },
    [tableContent, pagination, fetchArticles, filter]
  );

  const handlePaginationChange = useCallback(
    async (newPagination: { page: number; rowsPerPage: number }) => {
      setPagination(newPagination);
      setSelection(INITIAL_SELECTION);
      if (
        pagination.page === 1 &&
        newPagination.page === 1 &&
        newPagination.rowsPerPage < pagination.rowsPerPage
      ) {
        setTableContent({
          ...tableContent,
          items: tableContent.items.slice(0, newPagination.rowsPerPage),
        });
        return;
      }
      const newTableContent = await fetchArticles(filter, sort, newPagination);
      setTableContent(newTableContent);
    },
    [fetchArticles, tableContent, filter, pagination, sort]
  );

  const handleLibraryActionsComplete = useCallback(
    async (result: { message: string; isSuccess: boolean }) => {
      setLibraryActionsAlert({
        open: true,
        text: result.message,
        type: result.isSuccess ? 'success' : 'critical',
      });
      const newTableContent = await fetchArticles(filter, sort, pagination);
      setTableContent(newTableContent);
      setSelection(INITIAL_SELECTION);
    },
    [fetchArticles, filter, pagination, sort]
  );

  const handleLibraryActionsCancel = useCallback(
    () => setSelection(INITIAL_SELECTION),
    []
  );

  const isLoading = useMemo(
    () => user.isLoading || fetchArticlesStatus === RequestStatus.Loading,
    [fetchArticlesStatus, user.isLoading]
  );

  const selectedIds = useMemo(() => {
    return Object.keys(selection.byId).filter((id) =>
      Boolean(selection.byId[id])
    );
  }, [selection.byId]);

  useEffect(() => {
    async function fetchInitialArticles() {
      const newTableContent = await fetchArticles(
        INITIAL_FILTER,
        INITIAL_SORT,
        INITIAL_PAGINATION
      );
      setTableContent(newTableContent);
    }
    fetchInitialArticles();
  }, [fetchArticles]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <ManageLibraryFilter
        isLoading={isLoading}
        filter={filter}
        onFilterChange={handleFilterChange}
      />
      <TransitionGroup>
        {!isLoading && selection.isSomeSelected && (
          <Collapse>
            <ManageLibraryActions
              selectedIds={selectedIds}
              filterArticleStatus={filter.articleStatus}
              onCancel={handleLibraryActionsCancel}
              onComplete={handleLibraryActionsComplete}
              sx={(theme) => ({
                borderTopLeftRadius: theme.border.radius.md,
                borderTopRightRadius: theme.border.radius.md,
              })}
            />
          </Collapse>
        )}
      </TransitionGroup>
      <ManageLibraryTable
        isLoading={isLoading}
        items={tableContent.items}
        filter={filter}
        sort={sort}
        selection={selection}
        onSortChange={handleSortChange}
        onSelectionChange={setSelection}
        {...(!selection.isSomeSelected && {
          sx: (theme) => ({
            borderTopLeftRadius: theme.border.radius.md,
            borderTopRightRadius: theme.border.radius.md,
          }),
        })}
      />
      {tableContent.total > INITIAL_PAGINATION.rowsPerPage && (
        <TablePagination
          disabled={isLoading}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          count={
            tableContent.total % pagination.rowsPerPage > 0
              ? Math.trunc(tableContent.total / pagination.rowsPerPage) + 1
              : tableContent.total / pagination.rowsPerPage
          }
          onPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: pagination.rowsPerPage,
              page: value,
            })
          }
          onRowsPerPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: value,
              page: 1,
            })
          }
        />
      )}
      <AlertBar
        snackbar
        type="critical"
        open={fetchArticlesStatus === RequestStatus.Failure}
        text={translations.manageContentFetchArticlesError}
        onClose={() => setFetchArticlesStatus(RequestStatus.Idle)}
      />
      <AlertBar
        snackbar
        open={libraryActionsAlert.open}
        type={libraryActionsAlert.type}
        text={libraryActionsAlert.text}
        onClose={() =>
          setLibraryActionsAlert({ ...libraryActionsAlert, open: false })
        }
      />
    </Stack>
  );
};

export { ManageLibrary };
