import Stack from '@mui/material/Stack';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon } from 'componentsNew';
import { useMemo } from 'react';

export type FormFieldHelperProps = {
  id?: string;
  open: boolean;
  type: 'information' | 'warning' | 'critical';
  icon?: React.ReactNode;
  sx?: SxProps<Theme>;
  children: string;
};

const FormFieldHelper = ({
  id,
  open,
  type,
  icon,
  sx,
  children,
}: FormFieldHelperProps) => {
  const theme = useTheme();

  const uiSettings: {
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    icon: React.ReactNode;
  } = useMemo(() => {
    switch (type) {
      case 'critical':
        return {
          backgroundColor: theme.colors.surface.critical,
          borderColor: theme.colors.border.surfaceCritical,
          textColor: theme.colors.text.critical,
          icon: icon || (
            <Icon type="exclamationTriangleFilled" size={16} color="critical" />
          ),
        };
      case 'warning':
        return {
          backgroundColor: theme.colors.surface.warning,
          borderColor: theme.colors.border.surfaceWarning,
          textColor: theme.colors.text.warning,
          icon: icon || (
            <Icon type="bellAlertFilled" size={16} color="warning" />
          ),
        };
      default:
        return {
          backgroundColor: theme.colors.surface.informative,
          borderColor: theme.colors.border.surfaceInformative,
          textColor: theme.colors.text.informative,
          icon: icon || (
            <Icon type="informationCircleFilled" size={16} color="brandBase" />
          ),
        };
    }
  }, [type, icon, theme.colors]);

  if (!open) {
    return null;
  }

  return (
    <Stack
      id={id}
      sx={[
        (theme) => ({
          flexDirection: 'row',
          columnGap: theme.spacing('xxxs'),
          padding: theme.spacing('xxxs'),
          color: uiSettings.textColor,
          backgroundColor: uiSettings.backgroundColor,
          border: `${theme.border.thickness[1]} solid ${uiSettings.borderColor}`,
          borderRadius: theme.border.radius.sm,
          svg: { marginTop: '1px' },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {uiSettings.icon}
      <Typography sx={{ wordBreak: 'break-word' }} variant="caption">
        {children}
      </Typography>
    </Stack>
  );
};
export { FormFieldHelper };
