import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Select } from 'componentsNew';
import { translations } from 'translations';

type TablePaginationProps = {
  sx?: SxProps<Theme>;
  count: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  disabled?: boolean;
  onPageChange: (value: number) => void;
  onRowsPerPageChange: (value: number) => void;
};

const TablePagination = ({
  sx,
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  disabled,
  onPageChange,
  onRowsPerPageChange,
}: TablePaginationProps) => {
  return (
    <Stack
      sx={[
        (theme) => ({
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
          gap: theme.spacing('sm'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack
        sx={() => ({
          flexDirection: 'row',
          alignItems: 'center',
        })}
      >
        <Typography
          variant="body2"
          sx={(theme) => ({ color: theme.colors.text.tertiary })}
        >{`${translations.paginationRowsPerPage}:`}</Typography>
        <Select
          items={rowsPerPageOptions.map((option) => ({
            name: `${option}`,
            value: `${option}`,
          }))}
          value={{
            name: `${rowsPerPage}`,
            value: `${rowsPerPage}`,
          }}
          disabled={disabled}
          onChange={(item) => {
            onRowsPerPageChange(item ? Number(item.value) : 10);
          }}
          sx={(theme) => ({
            height: '2rem',
            backgroundColor: theme.colors.surface.secondary,
            marginLeft: theme.spacing('sm'),
          })}
        />
      </Stack>
      <Pagination
        count={count}
        page={page}
        sx={{ marginLeft: 'auto' }}
        variant="outlined"
        disabled={disabled}
        hidePrevButton={page === 1}
        hideNextButton={page === count}
        onChange={(_e, page) => onPageChange(page)}
      />
    </Stack>
  );
};

export { TablePagination };
