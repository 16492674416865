import Typography from '@mui/material/Typography';

export type FormFieldLabelProps = {
  size?: 'small' | 'large' | 'none';
  component?: React.ElementType;
  hide?: boolean;
  htmlFor?: string;
  children: string;
};

const FormFieldLabel = ({
  size = 'large',
  component = 'label',
  hide = false,
  htmlFor,
  children,
}: FormFieldLabelProps) => {
  if (hide) {
    return (
      <Typography variant="srOnly" component={component} htmlFor={htmlFor}>
        {children}
      </Typography>
    );
  }
  if (size === 'large') {
    return (
      <Typography
        variant="h4"
        component={component}
        htmlFor={htmlFor}
        sx={(theme) => ({
          fontWeight: 400,
          color: theme.colors.text.tertiary,
        })}
      >
        {children}
      </Typography>
    );
  }

  return (
    <Typography
      variant="body2"
      component={component}
      htmlFor={htmlFor}
      sx={() => ({ fontWeight: 'bold' })}
    >
      {children}
    </Typography>
  );
};

export { FormFieldLabel };
