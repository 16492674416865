import 'medium-draft/lib/index.css';
import 'medium-draft/lib/basic.css';
import './SideButtons/SideButtons.scss';
import './index.scss';

import classnames from 'classnames';
import Editable from 'components/Editable';
import { AtomicBlockUtils, convertToRaw, EditorState } from 'draft-js';
import {
  addNewBlock,
  Block,
  BLOCK_BUTTONS,
  createEditorState,
  Editor,
  rendererFn,
} from 'medium-draft';
import { setRenderOptions, styleToHTML } from 'medium-draft/lib/exporter';
import mediumDraftImporter, { htmlToStyle } from 'medium-draft/lib/importer';
import { Component } from 'react';
import { getImageSrc } from 'utils/cms';
import injectFullImageUrl from 'utils/cms/injectFullImageUrl';

import { blockToHTML, entityToHTML } from './export';
import { htmlToBlock, htmlToEntity } from './import';
import { AtomicBlock, AtomicSeparatorComponent } from './SideButtons/atomic';
import AtomicAccordionComponent from './SideButtons/EmbedAccordionButton/component';
import EmbedAccordionButton from './SideButtons/EmbedAccordionButton/index';
import EmbedExpandableImageButton from './SideButtons/EmbedExpandableImageButton';
import EmbedImageButton from './SideButtons/EmbedImageButton';
import EmbedMSFormsButton from './SideButtons/EmbedMSFormsButton';
import EmbedMSPowerBIButton from './SideButtons/EmbedMSPowerBIButton';
import EmbedPlayerenceButton from './SideButtons/EmbedPlayerenceButton';
import EmbedVideoButton from './SideButtons/EmbedVideoButton';
import { AtomicEmbedComponent } from './SideButtons/EmbedVideoButton/component';

const blockButtons = BLOCK_BUTTONS.map((button) => {
  if (button.style === 'header-three') {
    return {
      ...button,
      label: 'H2',
      style: 'header-two',
      description: 'Heading 2',
    };
  }
  if (button.style === 'todo') {
    return null;
  }
  return button;
}).filter((x) => x);

class RichText extends Component {
  constructor(props) {
    super(props);

    const { content } = props;
    if (content) {
      this.state = {
        editorState: createEditorState(
          convertToRaw(
            mediumDraftImporter(injectFullImageUrl(content), {
              htmlToStyle,
              htmlToEntity,
              htmlToBlock,
            })
          )
        ),
        editorEnabled: true,
      };
    } else {
      this.state = {
        editorState: createEditorState(),
        editorEnabled: true,
      };
    }

    this.exporter = setRenderOptions({
      styleToHTML,
      blockToHTML,
      entityToHTML,
    });
  }

  getContent = (editorState) => {
    const { multiline, isRaw = true } = this.props;

    if (!isRaw) {
      return { editorState: editorState.getCurrentContent() };
    }

    const rawContent = convertToRaw(editorState.getCurrentContent());

    let content;

    if (multiline) {
      content = rawContent.blocks.map((block) => block.text.trim()).join('\n');
    } else {
      content = rawContent.blocks[0].text.trim();
    }

    return content;
  };

  onChange = (editorState) => {
    const { onChange } = this.props;
    this.setState({ editorEnabled: true, editorState });

    if (!onChange) {
      return;
    }

    const output = this.getContent(editorState);

    onChange(output);
  };

  onBlur = () => {
    const { onBlur, changeBodyTextValue, form } = this.props;
    if (onBlur) {
      const currentContent = this.state.editorState.getCurrentContent();
      const eHTML = this.exporter(currentContent);
      const strippedHTML = eHTML.replace(/<p><\/p>/g, '');
      onBlur(strippedHTML);
      if (form === 'alert') {
        changeBodyTextValue(strippedHTML);
      }
    }
  };

  onAddImage = ({ url, height, width, expandable, altText }) => {
    this.onChange(
      addNewBlock(this.getEditorState(), Block.IMAGE, {
        src: getImageSrc(url),
        width: width,
        height: height,
        altText: altText,
        orientation: width > height ? 'landscape' : 'portrait',
        expandable,
      })
    );

    setTimeout(this.onBlur, 0);
  };

  onAddExpandableImage = (event) => {
    this.onAddImage({ ...event, expandable: true });
  };

  onEmbedURL = (url) => {
    let editorState = this.getEditorState();
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity('embed', 'IMMUTABLE', {
      url: (url.hostname || '').indexOf('cvm3.se') > -1 ? url.href : url,
    });
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    editorState = EditorState.push(
      editorState,
      contentWithEntity,
      'create-entity'
    );

    this.onChange(
      AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, 'A')
    );

    setTimeout(this.onBlur, 0);
  };

  onAddAccordion = ({ title, body }) => {
    let editorState = this.getEditorState();
    const content = editorState.getCurrentContent();

    const contentWithEntity = content.createEntity('accordion', 'IMMUTABLE', {
      title,
      body,
    });
    const entityKey = contentWithEntity.getLastCreatedEntityKey();

    const addedEntityKey = contentWithEntity.mergeEntityData(entityKey, {
      entityKey,
    });

    editorState = EditorState.push(
      editorState,
      addedEntityKey,
      'create-entity'
    );

    this.onChange(
      AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, 'accordion')
    );

    setTimeout(this.onBlur, 0);
  };

  onEditAccordion = ({ title, body, entityKey }) => {
    this.enableEditor();
    let editorState = this.getEditorState();
    const content = editorState.getCurrentContent();

    const contentWithUpdatedEntity = content.replaceEntityData(entityKey, {
      title,
      body,
      entityKey,
    });

    editorState = EditorState.push(
      editorState,
      contentWithUpdatedEntity,
      'update-entity'
    );

    this.onChange(editorState);

    setTimeout(this.onBlur, 0);
  };

  disableEditor = () => {
    this.setState({ ...this.state, editorEnabled: false });
  };

  enableEditor = () => {
    this.setState({ ...this.state, editorEnabled: true });
  };

  sideButtons = [
    {
      title: 'Video',
      component: EmbedVideoButton,
      props: {
        title: 'Video',
        onEmbedURL: this.onEmbedURL,
      },
    },
    {
      title: 'Image',
      component: EmbedImageButton,
      props: {
        title: 'Image',
        onAddImage: this.onAddImage,
      },
    },
    {
      title: 'Zoomable Image',
      component: EmbedExpandableImageButton,
      props: {
        title: 'Zoomable Image',
        onAddImage: this.onAddExpandableImage,
      },
    },
  ];

  componentDidMount = () => {
    const accordionButton = {
      title: 'Accordion',
      component: EmbedAccordionButton,
      props: {
        title: 'Accordion',
        onAddAccordion: this.onAddAccordion,
      },
    };
    const msFormsButton = {
      title: 'MS Forms',
      component: EmbedMSFormsButton,
      props: {
        title: 'MS Forms',
        onEmbedURL: this.onEmbedURL,
      },
    };
    const msPowerBiButton = {
      title: 'Power BI Report',
      component: EmbedMSPowerBIButton,
      props: {
        title: 'Power BI Report',
        onEmbedURL: this.onEmbedURL,
      },
    };
    const playerenceButton = {
      title: 'Playerence Quiz',
      component: EmbedPlayerenceButton,
      props: {
        title: 'Quiz',
        onEmbedURL: this.onEmbedURL,
      },
    };

    if (this.props.form === 'news') {
      this.sideButtons.push(msFormsButton);
      return;
    }

    if (this.props.form === 'blog') {
      this.sideButtons.push(msFormsButton);
      return;
    }

    if (this.props.form === 'topic') {
      this.sideButtons.push(playerenceButton);
      return;
    }

    if (this.props.form === 'library') {
      this.sideButtons.push(accordionButton);
      this.sideButtons.push(msFormsButton);
      this.sideButtons.push(msPowerBiButton);

      let editorState = this.getEditorState();
      const entityKeys = [];

      this.assignEntityKeysToEntityTypes(editorState, entityKeys, 'accordion');
      if (entityKeys.length > 0) {
        this.removeEmptySpaceBeforeAtomicBlocks(editorState, entityKeys);
      }
    }
  };

  assignEntityKeysToEntityTypes = (
    editorState,
    entityKeys,
    entityType = null
  ) => {
    const content = editorState.getCurrentContent();

    content.getBlocksAsArray().forEach((block) => {
      block.findEntityRanges(
        (character) => {
          if (character.getEntity() !== null) {
            const entity = content.getEntity(character.getEntity());

            if (
              !entityType ||
              (entityType && entity.getType() === entityType)
            ) {
              const addedEntityKey = content.mergeEntityData(
                character.getEntity(),
                {
                  entityKey: character.getEntity(),
                }
              );

              editorState = EditorState.push(
                editorState,
                addedEntityKey,
                'update-entity'
              );

              entityKeys.push(character.getEntity());

              return true;
            }
          }
          return false;
        },
        () => {}
      );
    });
    this.onChange(editorState);
  };

  removeEmptySpaceBeforeAtomicBlocks(editorState, entityKeys) {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const blockKeysToRemove = [];

    for (let index = 0; index < entityKeys.length; index++) {
      if (index === 0) continue;

      const currentAtomicBlock = blockMap.find(
        (block) => block.getEntityAt(0) === entityKeys[index]
      );

      const currentAtomicBlockKey = currentAtomicBlock.getKey();

      blockKeysToRemove.push(
        content.getBlockBefore(currentAtomicBlockKey).getKey()
      );
    }

    const newBlockMap = blockMap.filter(
      (block) => !blockKeysToRemove.includes(block.getKey())
    );

    const newContent = content.set('blockMap', newBlockMap);
    const newEditorState = EditorState.createWithContent(newContent);

    this.onChange(newEditorState);
  }

  rendererFn(setEditorState, getEditorState, extraProps) {
    const atomicRenderers = {
      embed: AtomicEmbedComponent,
      accordion: AtomicAccordionComponent,
      separator: AtomicSeparatorComponent,
    };
    const rFnOld = rendererFn(setEditorState, getEditorState);
    const rFnNew = (contentBlock) => {
      const type = contentBlock.getType();
      switch (type) {
        case Block.ATOMIC:
          return {
            component: AtomicBlock,
            editable: false,
            props: {
              components: atomicRenderers,
              getEditorState,
              onEditAccordion: extraProps.onEditAccordion,
              disableEditor: extraProps.disableEditor,
              enableEditor: extraProps.enableEditor,
            },
          };
        default:
          return rFnOld(contentBlock);
      }
    };
    return rFnNew;
  }

  getEditorState = () => this.state.editorState;

  render() {
    const {
      showSideButtons,
      wrapper: { element: Element, className },
    } = this.props;
    const { editorState } = this.state;
    return (
      <Editable>
        <Element
          className={classnames(className, {
            'rich-text__library-edit': this.props.form === 'library',
            'rich-text__news': this.props.form === 'news',
            'rich-text__blog': this.props.form === 'blog',
            'rich-text__topic': this.props.form === 'topic',
          })}
        >
          <Editor
            editorEnabled={this.state.editorEnabled}
            ref={(e) => (this.editor = e)}
            editorState={editorState}
            getEditorState={this.getEditorState}
            placeholder={this.props.placeholder}
            onChange={this.onChange}
            onBlur={this.onBlur}
            sideButtons={showSideButtons ? this.sideButtons : []}
            rendererFn={(setEditorState, getEditorState) =>
              this.rendererFn(setEditorState, getEditorState, {
                onEditAccordion: this.onEditAccordion,
                disableEditor: this.disableEditor,
                enableEditor: this.enableEditor,
              })
            }
            blockButtons={blockButtons}
          />
        </Element>
      </Editable>
    );
  }
}

RichText.defaultProps = {
  wrapper: {
    element: 'div',
    className: '',
  },
};

export default RichText;
