import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import SearchHit from 'api/models/SearchHit';
import { Icon } from 'componentsNew';
import { translations } from 'translations';

type BrandPortalSearchResult = {
  id: string;
  title: string;
  assets: {
    id: string;
    title: string;
    fileType: string;
    fileSize: string;
    linkUrl: string;
    downloadUrl: string;
    thumbnailUrl: string;
  }[];
};

type BrandPortalItemProps = {
  id?: string;
  sx?: SxProps<Theme>;
  searchHit: SearchHit;
};

const BrandPortalItem = ({ id, sx, searchHit }: BrandPortalItemProps) => {
  const attributes = searchHit.getAttributes();
  const includes = searchHit.getIncludes();

  const result: BrandPortalSearchResult = {
    id: attributes.id,
    title: attributes.title,
    assets: includes.assets,
  };

  return (
    <Stack
      id={id}
      sx={[
        (theme) => ({ gap: theme.spacing('sm') }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack sx={(theme) => ({ gap: theme.spacing('xxxs') })}>
        <Typography
          variant="body2"
          sx={(theme) => ({ color: theme.colors.text.tertiary })}
        >
          {translations.searchHitBrandPortal}/
          <b>{translations.searchHitBrandPortalAssets}</b>
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({ color: theme.colors.text.tertiary })}
        >
          {result.title}
        </Typography>
      </Stack>
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          gap: theme.spacing('sm'),
          flexWrap: 'wrap',
        })}
      >
        {result.assets.map((asset) => (
          <Stack
            key={asset.id}
            sx={(theme) => ({
              backgroundColor: theme.colors.surface.secondary,
              border: `1px solid ${theme.colors.border.surfacePrimary}`,
              borderRadius: theme.border.radius.sm,
              padding: theme.spacing('xxs'),
              gap: theme.spacing('xxs'),
              width: '10.5rem',
            })}
          >
            <Stack
              sx={(theme) => ({
                width: '100%',
                aspectRatio: 16 / 9,
                backgroundColor: theme.colors.surface.primary,
                justifyContent: 'center',
                padding: theme.spacing('xxs'),
              })}
            >
              <Box
                component="img"
                sx={() => ({
                  display: 'block',
                  width: '100%',
                })}
                src={asset.thumbnailUrl}
              />
            </Stack>
            <Link
              variant="body2"
              href={asset.linkUrl}
              target="_blank"
              sx={(theme) => ({
                color: theme.colors.text.secondary,
                textDecoration: 'none',
              })}
            >
              {asset.title}
            </Link>
            <Stack
              sx={() => ({
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 'auto',
              })}
            >
              <Stack
                sx={(theme) => ({
                  flexDirection: 'row',
                  gap: theme.spacing('xxs'),
                })}
              >
                <Typography
                  variant="caption"
                  sx={(theme) => ({ color: theme.colors.text.tertiary })}
                >
                  {asset.fileSize}
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={(theme) => ({
                    height: '1rem',
                    borderColor: theme.colors.border.surfaceSecondary,
                  })}
                />
                <Typography
                  variant="caption"
                  sx={(theme) => ({ color: theme.colors.text.tertiary })}
                >
                  {asset.fileType}
                </Typography>
              </Stack>

              <Button
                id={`${id}-download`}
                size="small"
                variant="text"
                href={asset.downloadUrl}
                target="_blank"
                startIcon={<Icon type="arrowDownTray" color="brandBase" />}
                sx={{
                  padding: '0 !important',
                  height: '2rem',
                  width: '2rem',
                  minWidth: 'unset',
                  '> .MuiButton-startIcon': {
                    marginRight: 0,
                    marginLeft: 0,
                  },
                }}
              />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default BrandPortalItem;
