import Typography from '@mui/material/Typography';
import * as cmsLibraryApi from 'api/cms/library';
import { UserContext } from 'components/Context/User';
import { LinkList } from 'componentsNew';
import { PageContentCard, PageContentHeader } from 'layoutNew';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { translations } from 'translations';
import { GAonClickHomeUpdatedLibraryPages } from 'utils/analytics';
import { getDateOrDaysAgo } from 'utils/misc/time';

import { NoUpdatedLibraryPages } from './NoUpdatedLibraryPages';
import { UpdatedLibraryPagesSkeleton } from './UpdatedLibraryPagesSkeleton';

type LibraryArticlePreview = {
  _doc: string;
  title: string;
  linkPath?: string;
  region?: {
    id: string;
    name: string;
  };
  country?: {
    id: string;
    name: string;
  };
  division?: {
    id: string;
    name: string;
  };
  lastModifiedBy: {
    date: string;
  };
  locationTag?: string;
};

const elementId = 'home-updated-library-pages';
const articleQueryLimit = 5;

const UpdatedLibraryPages = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [articles, setArticles] = useState<LibraryArticlePreview[]>([]);

  const user = useContext(UserContext);
  const mountedRef = useRef<boolean>(true);

  const fetchUpdatedLibraryArticles = useCallback(async () => {
    if (user.isLoading) {
      return;
    }
    setIsLoading(true);

    try {
      const query = `?limit=${articleQueryLimit}${
        user.regionId && `&regionId=${user.regionId}`
      }${user.countryId && `&countryId=${user.countryId}`}${
        user.divisionId && `&divisionId=${user.divisionId}`
      }`;
      const response = await cmsLibraryApi.getLastModifiedArticles(query);
      const newArticles = response.data.data as LibraryArticlePreview[];
      if (!mountedRef.current) return;
      setArticles(newArticles);
    } finally {
      if (!mountedRef.current) return;
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUpdatedLibraryArticles();
  }, [fetchUpdatedLibraryArticles]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (user.isLoading || isLoading) {
    return (
      <PageContentCard>
        <PageContentHeader text={translations.updatedLibraryArticlesTitle} />
        <UpdatedLibraryPagesSkeleton />
      </PageContentCard>
    );
  }

  if (!articles.length) {
    return (
      <PageContentCard>
        <PageContentHeader text={translations.updatedLibraryArticlesTitle} />
        <NoUpdatedLibraryPages />
      </PageContentCard>
    );
  }

  return (
    <PageContentCard>
      <PageContentHeader text={translations.updatedLibraryArticlesTitle} />
      <LinkList
        id={elementId}
        sx={{ '.MuiTypography-body1': { fontWeight: 'unset' } }}
        onClick={(item) => GAonClickHomeUpdatedLibraryPages(item.title)}
        items={articles.map((article, index) => ({
          id: article._doc,
          title: article.title,
          subDescriptions: [article.locationTag || translations.global],
          to: article.linkPath,
          divider: index < articles.length - 1,
          element: (
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.colors.text.tertiary })}
            >
              {getDateOrDaysAgo(article.lastModifiedBy.date)}
            </Typography>
          ),
        }))}
      />
    </PageContentCard>
  );
};

export { UpdatedLibraryPages };
