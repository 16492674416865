import Box from '@mui/material/Box';
import { TypographyWithLink } from 'componentsNew/TypographyWithLink';
import { translations } from 'translations';

import * as helpers from './helpers';

const NoInsightsDatabaseArticles = () => {
  return (
    <Box maxWidth="30rem" marginBottom={2}>
      <TypographyWithLink
        to={helpers.INSIGHTS_DATABASE_LINK_URL}
        variant="body2"
        sx={(theme) => ({
          color: theme.colors.text.tertiary,
        })}
      >
        {translations.insightsDatabaseNoItemsTextWithLink}
      </TypographyWithLink>
    </Box>
  );
};

export { NoInsightsDatabaseArticles };
