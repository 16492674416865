import settings from 'settings';

const { redirectUri } = settings.auth;

let dummyId = 0;

class DataModel {
  constructor(className, id, attributes, includes) {
    this.attributes = attributes;
    this.className = className;
    this.id = id;
    this.includes = includes || {};
  }

  static createLoading(Class, count) {
    const out = [];

    for (let i = 0; i < count; ++i) {
      const object = new Class(`loading_${dummyId}`, {});
      object.__loading = true;
      dummyId += 1;

      out.push(object);
    }

    return out;
  }

  isLoading() {
    return this.__loading;
  }

  getPersonName() {
    const { firstName, lastName } = this.attributes;
    return `${firstName} ${lastName}`;
  }

  getOid() {
    const { id } = this;

    return id.substr(id.indexOf('-') + 1);
  }

  getUrl() {
    const { linkUrl } = this.attributes;

    if (linkUrl) {
      return linkUrl.replace(redirectUri, '');
    }

    return null;
  }

  get(key) {
    return this.attributes[key];
  }

  set(key, value) {
    this.attributes[key] = value;
  }

  getAttributes() {
    return this.attributes;
  }

  getIncludes() {
    return this.includes;
  }
}

export default DataModel;
