import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import OnlyWithRole from 'components/OnlyWithRole';
import { Radio } from 'componentsNew';
import { useCallback } from 'react';
import { translations } from 'translations';
import { GAonChangeManageLibraryFilter } from 'utils/analytics';

const elementId = 'profile-manage-library-filter';

export enum ArticleStatus {
  Published = 'Published',
  Unpublished = 'Unpublished',
  Drafts = 'Drafts',
  Ownerless = 'Ownerless',
}

type ManageLibraryFilterProps = {
  isLoading: boolean;
  filter: {
    isAdmin: boolean;
    articleStatus: ArticleStatus;
  };
  onFilterChange: (filter: {
    isAdmin: boolean;
    articleStatus: ArticleStatus;
  }) => void;
};

const ManageLibraryFilter = ({
  isLoading,
  filter,
  onFilterChange,
}: ManageLibraryFilterProps) => {
  const onAdminChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFilter = {
        ...filter,
        isAdmin: e.target.checked,
        articleStatus:
          filter.isAdmin && filter.articleStatus === ArticleStatus.Ownerless
            ? ArticleStatus.Published
            : filter.articleStatus,
      };
      onFilterChange(newFilter);
      GAonChangeManageLibraryFilter(
        newFilter.isAdmin
          ? `${newFilter.articleStatus}, Admin`
          : `${newFilter.articleStatus}, Non-Admin`
      );
    },
    [filter, onFilterChange]
  );

  const onArticleStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFilter = {
        ...filter,
        articleStatus: e.target.value as ArticleStatus,
      };
      onFilterChange(newFilter);
      GAonChangeManageLibraryFilter(
        newFilter.isAdmin
          ? `${newFilter.articleStatus}, Admin`
          : `${newFilter.articleStatus}, Non-Admin`
      );
    },
    [filter, onFilterChange]
  );

  return (
    <Stack
      sx={(theme) => ({
        gap: theme.spacing('sm'),
        padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
      })}
    >
      <OnlyWithRole viewableByRole="AvenueSuperuser">
        <FormControlLabel
          label={translations.manageContentAdmin}
          labelPlacement="start"
          sx={{ alignSelf: 'flex-end' }}
          control={
            <Switch
              id={`${elementId}-admin`}
              checked={filter.isAdmin}
              disabled={isLoading}
              onChange={onAdminChange}
            />
          }
        />
        <Divider />
      </OnlyWithRole>
      <RadioGroup
        value={filter.articleStatus}
        sx={(theme) => ({
          flexDirection: 'row',
          gap: theme.spacing('xs'),
          margin: `${theme.spacing('xxxs')} 0`,
        })}
        onChange={onArticleStatusChange}
      >
        <FormControlLabel
          label={translations.manageContentPublished}
          value={ArticleStatus.Published}
          control={<Radio id={`${elementId}-published`} disabled={isLoading} />}
        />
        <FormControlLabel
          label={translations.manageContentUnpublished}
          value={ArticleStatus.Unpublished}
          control={
            <Radio id={`${elementId}-unpublished`} disabled={isLoading} />
          }
        />
        <FormControlLabel
          label={translations.manageContentDrafts}
          value={ArticleStatus.Drafts}
          control={<Radio id={`${elementId}-drafts`} disabled={isLoading} />}
        />
        {filter.isAdmin && (
          <FormControlLabel
            label={translations.manageContentOwnerless}
            value={ArticleStatus.Ownerless}
            control={
              <Radio id={`${elementId}-ownerless`} disabled={isLoading} />
            }
          />
        )}
      </RadioGroup>
    </Stack>
  );
};

export { ManageLibraryFilter };
