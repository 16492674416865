import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Unstable_Grid2';
import Hero from 'components/Hero';
import { DatePicker } from 'componentsNew';
import { AvenueRoute } from 'constants/routes';
import {
  Form,
  FormButtons,
  FormFieldWrapper,
  FormLoading,
  FormStack,
  PageContentCard,
} from 'layoutNew';
import { useCallback } from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import * as cmsUtils from 'utils/cms';
import * as formUtils from 'utils/form';

import { NewsletterBannerFormSkeleton } from './NewsletterBannerFormSkeleton';

const elementId = 'newsletter-banner-form';

export type HeroImage = {
  raw?: {
    file: string;
    blob: Blob;
    height: number;
    width: number;
  };
  uploaded?: string;
  altText?: string;
};

export type FormValues = {
  title: string;
  description: string;
  linkUrl: string;
  heroImage: HeroImage;
  publishedDate: Date | null;
};

type NewsletterBannerFormProps = {
  beforeSubmit?: () => void | Promise<void>;
  onSubmit: SubmitHandler<FormValues>;
  onDelete?: () => void;
};

export const DEFAULT_VALUES: FormValues = {
  title: '',
  description: '',
  linkUrl: '',
  heroImage: {},
  publishedDate: new Date(),
};

const NewsletterBannerForm = ({
  beforeSubmit,
  onSubmit,
  onDelete,
}: NewsletterBannerFormProps) => {
  const history = useHistory();
  const location = useLocation();
  const methods = useFormContext<FormValues>();

  const {
    control,
    formState: { errors, isLoading, isSubmitting },
    handleSubmit,
  } = methods;

  const onCancel = useCallback(() => {
    const canGoBack = Boolean(location.key);
    if (canGoBack) {
      history.goBack();
    } else {
      history.push(AvenueRoute.Home);
    }
  }, [location, history]);

  if (isLoading) {
    return (
      <Form id={elementId}>
        <Grid container>
          <Grid xs={12} lg={8}>
            <PageContentCard variant="elevated">
              <NewsletterBannerFormSkeleton />
            </PageContentCard>
          </Grid>
        </Grid>
      </Form>
    );
  }

  return (
    <Form
      id={elementId}
      onSubmit={async (e) => {
        e.persist();
        beforeSubmit && (await beforeSubmit());
        handleSubmit(onSubmit)(e);
      }}
    >
      <Grid container>
        <Grid xs={12} lg={8}>
          <PageContentCard variant="elevated">
            <FormLoading isLoading={isSubmitting} scroll />
            <FormStack>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formUtils.getErrorMessage('required', {
                      displayName: translations.formLabelTitle,
                    }),
                  },
                  maxLength: {
                    value: 55,
                    message: formUtils.getErrorMessage('maxLength', {
                      displayName: translations.formLabelTitle,
                      value: 55,
                    }),
                  },
                }}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-title`}
                      label={translations.formLabelTitle}
                      error={errors.title?.message}
                    >
                      <OutlinedInput
                        {...field}
                        size="small"
                        inputRef={ref}
                        error={Boolean(errors.title)}
                        fullWidth
                      />
                    </FormFieldWrapper>
                  );
                }}
              />
              <Controller
                name="description"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formUtils.getErrorMessage('required', {
                      displayName: translations.formLabelDescription,
                    }),
                  },
                  maxLength: {
                    value: 170,
                    message: formUtils.getErrorMessage('maxLength', {
                      displayName: translations.formLabelDescription,
                      value: 170,
                    }),
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormFieldWrapper
                    id={`${elementId}-description`}
                    label={translations.formLabelDescription}
                    error={errors.description?.message}
                  >
                    <OutlinedInput
                      {...field}
                      size="small"
                      inputRef={ref}
                      error={Boolean(errors.description)}
                      fullWidth
                    />
                  </FormFieldWrapper>
                )}
              />
              <Controller
                name="linkUrl"
                control={control}
                rules={{
                  validate: (value) =>
                    formUtils.customValidators.url(value) ||
                    formUtils.getErrorMessage('url', {
                      displayName: translations.formLabelLink,
                    }),
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormFieldWrapper
                    id={`${elementId}-linkUrl`}
                    label={translations.formLabelLink}
                    error={errors.linkUrl?.message}
                  >
                    <OutlinedInput
                      {...field}
                      size="small"
                      inputRef={ref}
                      error={Boolean(errors.linkUrl)}
                      fullWidth
                    />
                  </FormFieldWrapper>
                )}
              />
              <Controller
                name="heroImage"
                control={control}
                render={({ field }) => (
                  <FormFieldWrapper
                    id={`${elementId}-heroImage`}
                    label={translations.formLabelImage}
                    error={errors.heroImage?.message}
                    sx={{
                      '.editable__hero': { marginBottom: '0 !important' },
                    }}
                  >
                    <Hero
                      useVideo={false}
                      useAltText={true}
                      aspectRatio={3 / 2}
                      displayType="feed"
                      minWidth={480}
                      heroImage={cmsUtils.getImageSrc(field.value.uploaded)}
                      heroAltText={field.value.altText}
                      onChange={(value: any) => {
                        if (!value?.heroImage) {
                          field.onChange({});
                          return;
                        }
                        const { altText, blob, file, height, width } =
                          value.heroImage;

                        const newHeroImage: HeroImage = {
                          uploaded: field.value.uploaded,
                          altText: altText,
                          raw: { blob, file, height, width },
                        };
                        field.onChange(newHeroImage);
                      }}
                    />
                  </FormFieldWrapper>
                )}
              />
              <Controller
                name="publishedDate"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formUtils.getErrorMessage('required', {
                      displayName: translations.formLabelPublishDate,
                    }),
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormFieldWrapper
                    id={`${elementId}-publishedDate`}
                    label={translations.formLabelPublishDate}
                    error={errors.publishedDate?.message}
                  >
                    <DatePicker {...field} inputRef={ref} />
                  </FormFieldWrapper>
                )}
              />
              <FormButtons>
                {onDelete && (
                  <Button variant="outlined" onClick={onDelete}>
                    {translations.delete}
                  </Button>
                )}
                <Button
                  variant="text"
                  onClick={onCancel}
                  sx={{ marginLeft: { xs: 0, sm: 'auto' } }}
                >
                  {translations.cancel}
                </Button>
                <Button variant="contained" type="submit">
                  {translations.publish}
                </Button>
              </FormButtons>
            </FormStack>
          </PageContentCard>
        </Grid>
      </Grid>
    </Form>
  );
};

export { NewsletterBannerForm };
