import { Department, Division, Segment } from 'types';

import {
  DepartmentsResponse,
  DivisionsResponse,
  DivisionWithCountries,
  Profile,
  ProfileResponse,
  SegmentsResponse,
} from './types';

export const GLOBAL_DIVISION_NAME = 'Global';
export const GLOBAL_DIVISION_ID = 'division-34';

export const ENTRANCE_SYSTEMS_DIVISION_ID = 'division-26';

const divisionsResponseToDivisions = (
  response: DivisionsResponse,
  excludeGlobal?: boolean
): Division[] => {
  const items = response.data.data;

  const globalItem = items.find(
    (item) => item.relationships.parentDivision.data === null
  );

  const divisions = items
    .filter((item) => {
      const parent = item.relationships.parentDivision.data;
      return parent && parent.id === globalItem?.id;
    })
    .map((item) => {
      const division: Division = {
        id: item.id,
        name: item.attributes.name,
      };
      return division;
    })
    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

  if (globalItem && !excludeGlobal) {
    const globalDivision: Division = {
      id: globalItem.id,
      name: globalItem.attributes.name,
    };
    return [globalDivision, ...divisions];
  }
  return divisions;
};

const divisionsResponseToDivisionsWithCountries = (
  response: DivisionsResponse
): DivisionWithCountries[] => {
  const items = response.data.data;
  const includedData = response.data.included;
  const globalItem = items.find(
    (item) => item.relationships.parentDivision.data === null
  );

  const divisionItems = items.filter((item) => {
    const parent = item.relationships.parentDivision.data;
    return parent && parent.id === globalItem?.id;
  });

  const divisionsWithCountries: DivisionWithCountries[] = divisionItems.map(
    (divisionItem) => {
      const divisionChildCountryIds =
        divisionItem.relationships.childCountries.data.map(
          (country) => country.id
        );
      const divisionChildCountries = divisionChildCountryIds.map(
        (divisionChildCountryId) => {
          const divisionChildCountryName = includedData.find(
            (data) =>
              data.type === 'countries' && data.id === divisionChildCountryId
          )?.attributes.name;
          if (!divisionChildCountryName) {
            return null;
          }
          return {
            id: divisionChildCountryId,
            name: divisionChildCountryName,
          };
        }
      );
      const divisionWithCountries: DivisionWithCountries = {
        id: divisionItem.id,
        name: divisionItem.attributes.name,
        countries: divisionChildCountries.filter(Boolean) as {
          id: string;
          name: string;
        }[],
      };
      return divisionWithCountries;
    }
  );
  return divisionsWithCountries.sort((a, b) =>
    a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  );
};

const departmentsResponseToDepartments = (
  response: DepartmentsResponse
): Department[] => {
  const items = response.data.data;
  return items.map((item) => ({ id: item.id, name: item.attributes.name }));
};

const segmentsResponseToSegments = (response: SegmentsResponse): Segment[] => {
  const items = response.data.data;
  return items.map((item) => ({ id: item.id, name: item.attributes.name }));
};

const profileResponseToProfile = (response: ProfileResponse): Profile => {
  return {
    id: response.data.data.id,
    ...response.data.data.attributes,
  };
};

export {
  departmentsResponseToDepartments,
  divisionsResponseToDivisions,
  divisionsResponseToDivisionsWithCountries,
  profileResponseToProfile,
  segmentsResponseToSegments,
};
