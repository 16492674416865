import { CMSConsumer } from 'components/Context/Library';
import { useUser } from 'components/Context/User';
import LibraryMenu from 'components/LibraryMenu';
import Title from 'components/Title';
import { useLibrary } from 'contextNew/Library';
import { NavLink } from 'react-router-dom';

const TreePicker = ({ url }) => {
  const user = useUser();
  const library = useLibrary();

  if (library.isRootNodesLoading) {
    return null;
  }
  const departments = library.rootNodes;

  return (
    <CMSConsumer>
      {({ setLibraryLocation, updateBreadcrumb }) => {
        //show only Global department for user with role 'AvenueGlobalOnlyUser'
        if (user.roles && user.roles.includes('AvenueGlobalOnlyUser')) {
          departments.length = 1;
        }

        if (url.startsWith('/group-library')) {
          return (
            <Title inline="true" color="white">
              Group Library
            </Title>
          );
        } else if (departments.length === 2) {
          return (
            <Title
              inline="true"
              color="white"
              onClick={() => {
                setLibraryLocation(departments[1].path);
              }}
            >
              {departments[1].title}
            </Title>
          );
        } else {
          return (
            <LibraryMenu>
              {departments.slice(1, departments.length).map((item, i, arr) => {
                const path = item.path;
                const nextPath = arr[i + 1] ? arr[i + 1].path : null;
                const nextPlusOnePath = arr[i + 2] ? arr[i + 2].path : null;

                return (
                  <NavLink
                    key={path}
                    to={`/library${path}`}
                    isActive={(_, location) => {
                      return (
                        location.pathname.includes(path) &&
                        !location.pathname.includes(nextPath) &&
                        !location.pathname.includes(nextPlusOnePath)
                      );
                    }}
                    onClick={() => {
                      updateBreadcrumb(`/library${path}`, []);
                      setLibraryLocation(path);
                    }}
                  >
                    {item.title}
                  </NavLink>
                );
              })}
            </LibraryMenu>
          );
        }
      }}
    </CMSConsumer>
  );
};
export default TreePicker;
