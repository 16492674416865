import './Navigation.scss';

import { useUser } from 'components/Context/User';
import OnlyWithRole from 'components/OnlyWithRole';
import UserImage from 'components/UserImage';
import { AvenueRoute } from 'constants/routes';
import { Link } from 'react-router-dom';
import { logout } from 'utils/auth';

import NavigationItem from './NavigationItem';

export const UserLink = () => {
  const user = useUser();

  return (
    <Link className="navigation__user" to={AvenueRoute.Profile}>
      <UserImage
        src={user.imageUrl}
        name={user.displayName}
        initials={user.initials}
      />
      <div className="navigation__details">
        <span className="navigation__name">{user.displayName}</span>
        {user.divisionName && user.countryName ? (
          <span className="navigation__location">{`${user.divisionName} | ${user.countryName}`}</span>
        ) : user.divisionName ? (
          <span className="navigation__location">{user.divisionName}</span>
        ) : null}
      </div>
    </Link>
  );
};

export const UserLinkList = () => (
  <ul className="navigation__items navigation__items--bottom-group">
    <NavigationItem to={AvenueRoute.Profile} color="white">
      Profile Settings
    </NavigationItem>
    <OnlyWithRole
      isAllRolesRequired={false}
      viewableByRole={['AvenueNotificationEditor', 'AvenueSuperuser']}
    >
      <NavigationItem to="/alert" color="white">
        Alerts
      </NavigationItem>
    </OnlyWithRole>
    <hr className="navigation__item-divider" />
    <NavigationItem to="/ " color="white" onClick={logout}>
      Log Out
    </NavigationItem>
  </ul>
);
