import * as cmsLibraryApi from 'api/cms/library';
import { LibraryRootNode } from 'api/cms/library/types';
import { useUser } from 'components/Context/User';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const LibraryContext = React.createContext<{
  myLibraryPath: string | null;
  rootNodes: LibraryRootNode[];
  isRootNodesLoading: boolean;
} | null>(null);

type LibraryProviderProps = {
  children: React.ReactNode;
};

const LibraryProvider = ({ children }: LibraryProviderProps) => {
  const [myLibraryPath, setMyLibraryPath] = useState<string | null>(null);

  const [rootNodes, setRootNodes] = useState<LibraryRootNode[]>([
    {
      title: 'Global',
      path: '/group-library',
      folderType: 'global',
    },
  ]);

  const [isRootNodesLoading, setIsRootNodesLoading] = useState<boolean>(false);

  const user = useUser();

  const fetchLibraryRootNodes = useCallback(
    async (user: {
      divisionId: string;
      countryId?: string;
      regionId?: string;
    }) => {
      try {
        setIsRootNodesLoading(true);
        const response = await cmsLibraryApi.getHomeNode({
          divisionId: user.divisionId,
          countryId: user.countryId,
          regionId: user.regionId,
        });
        const data = response?.data?.data;

        if (data?.departments) {
          setRootNodes(data.departments as LibraryRootNode[]);
        }
        if (data?.path) {
          setMyLibraryPath(data.path as string);
        }
      } catch {
      } finally {
        setIsRootNodesLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (!user.divisionId || user.isGlobalOnlyUser) {
      return;
    }
    fetchLibraryRootNodes({
      divisionId: user.divisionId,
      countryId: user.countryId,
      regionId: user.regionId,
    });
  }, [
    fetchLibraryRootNodes,
    user.countryId,
    user.divisionId,
    user.isGlobalOnlyUser,
    user.regionId,
  ]);

  const contextValue = useMemo(
    () => ({
      myLibraryPath,
      rootNodes,
      isRootNodesLoading: isRootNodesLoading || user.isLoading,
    }),
    [myLibraryPath, rootNodes, isRootNodesLoading, user.isLoading]
  );

  return (
    <LibraryContext.Provider value={contextValue}>
      {children}
    </LibraryContext.Provider>
  );
};

const useLibrary = () => {
  const context = useContext(LibraryContext);
  if (!context) {
    throw new Error('LibraryContext is not defined');
  }
  return context;
};

const LibraryConsumer = LibraryContext.Consumer;

export { LibraryConsumer, LibraryProvider, useLibrary };
