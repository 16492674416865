import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { Radio } from 'componentsNew';
import { useCallback } from 'react';
import { translations } from 'translations';

const elementId = 'profile-manage-insights-database-filter';

export enum ArticleStatus {
  Published = 'Published',
  Drafts = 'Drafts',
}

type ManageInsightsDatabaseFilterProps = {
  isLoading: boolean;
  filter: { articleStatus: ArticleStatus };
  onFilterChange: (filter: { articleStatus: ArticleStatus }) => void;
};

const ManageInsightsDatabaseFilter = ({
  isLoading,
  filter,
  onFilterChange,
}: ManageInsightsDatabaseFilterProps) => {
  const onArticleStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFilter = {
        ...filter,
        articleStatus: e.target.value as ArticleStatus,
      };
      onFilterChange(newFilter);
    },
    [filter, onFilterChange]
  );

  return (
    <Stack
      sx={(theme) => ({
        gap: theme.spacing('sm'),
        padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
      })}
    >
      <RadioGroup
        value={filter.articleStatus}
        sx={(theme) => ({
          flexDirection: 'row',
          gap: theme.spacing('xs'),
          margin: `${theme.spacing('xxxs')} 0`,
        })}
        onChange={onArticleStatusChange}
      >
        <FormControlLabel
          label={translations.manageContentPublished}
          value={ArticleStatus.Published}
          control={<Radio id={`${elementId}-published`} disabled={isLoading} />}
        />
        <FormControlLabel
          label={translations.manageContentDrafts}
          value={ArticleStatus.Drafts}
          control={<Radio id={`${elementId}-drafts`} disabled={isLoading} />}
        />
      </RadioGroup>
    </Stack>
  );
};

export { ManageInsightsDatabaseFilter };
