import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import OnlyWithRole from 'components/OnlyWithRole';
import { Radio } from 'componentsNew';
import { useCallback } from 'react';
import { translations } from 'translations';
import { GAonChangeManageFeedFilter } from 'utils/analytics';

const elementId = 'profile-manage-feed-filter';

export enum ArticleStatus {
  Published = 'Published',
  Drafts = 'Drafts',
  Scheduled = 'Scheduled',
}

type ManageFeedFilterProps = {
  isLoading: boolean;
  filter: {
    isAdmin: boolean;
    articleStatus: ArticleStatus;
  };
  onFilterChange: (filter: {
    isAdmin: boolean;
    articleStatus: ArticleStatus;
  }) => void;
};

const ManageFeedFilter = ({
  isLoading,
  filter,
  onFilterChange,
}: ManageFeedFilterProps) => {
  const onAdminChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFilter = {
        ...filter,
        isAdmin: e.target.checked,
        articleStatus: ArticleStatus.Published,
      };
      onFilterChange(newFilter);
      GAonChangeManageFeedFilter(
        newFilter.isAdmin
          ? `${newFilter.articleStatus}, Admin`
          : `${newFilter.articleStatus}, Non-Admin`
      );
    },
    [filter, onFilterChange]
  );

  const onArticleStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFilter = {
        ...filter,
        articleStatus: e.target.value as ArticleStatus,
      };
      onFilterChange(newFilter);
      GAonChangeManageFeedFilter(
        newFilter.isAdmin
          ? `${newFilter.articleStatus}, Admin`
          : `${newFilter.articleStatus}, Non-Admin`
      );
    },
    [filter, onFilterChange]
  );

  return (
    <Stack
      sx={(theme) => ({
        gap: theme.spacing('sm'),
        padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
      })}
    >
      <OnlyWithRole viewableByRole="AvenueSuperuser">
        <FormControlLabel
          label={translations.manageContentAdmin}
          labelPlacement="start"
          sx={{ alignSelf: 'flex-end' }}
          control={
            <Switch
              id={`${elementId}-admin`}
              checked={filter.isAdmin}
              disabled={isLoading}
              onChange={onAdminChange}
            />
          }
        />
        <Divider />
      </OnlyWithRole>
      <RadioGroup
        value={filter.articleStatus}
        sx={(theme) => ({
          flexDirection: 'row',
          gap: theme.spacing('xs'),
          margin: `${theme.spacing('xxxs')} 0`,
        })}
        onChange={onArticleStatusChange}
      >
        <FormControlLabel
          label={translations.manageContentPublished}
          value={ArticleStatus.Published}
          control={<Radio id={`${elementId}-published`} disabled={isLoading} />}
        />
        {!filter.isAdmin && (
          <>
            <FormControlLabel
              label={translations.manageContentDrafts}
              value={ArticleStatus.Drafts}
              control={
                <Radio id={`${elementId}-drafts`} disabled={isLoading} />
              }
            />
            <FormControlLabel
              label={translations.manageContentScheduled}
              value={ArticleStatus.Scheduled}
              control={
                <Radio id={`${elementId}-scheduled`} disabled={isLoading} />
              }
            />
          </>
        )}
      </RadioGroup>
    </Stack>
  );
};

export { ManageFeedFilter };
