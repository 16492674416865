import * as cmsLibraryApi from 'api/cms/library/index';
import * as cmsLibraryTransformers from 'api/cms/library/transformers';
import { MenuItem } from 'api/cms/library/types';
import Select from 'components/Select';
import { useEffect, useMemo, useState } from 'react';

import { Consumer } from '..';

export interface SelectOption {
  name: string;
  value: string;
  checked: boolean;
  disabled: boolean;
}

const MenuItemSelect = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    async function fetchInitialData() {
      const menuItems = await fetchMenuItems();
      if (!menuItems) return;
      setMenuItems(menuItems);
    }
    fetchInitialData();
  }, []);

  const fetchMenuItems = async () => {
    const response = await cmsLibraryApi.getMenuItems();
    const menuItems =
      cmsLibraryTransformers.menuItemsResponseToMenuItems(response);
    return menuItems;
  };

  const allOptions = useMemo((): SelectOption[] => {
    return menuItems.map((menuItem) => {
      const option: SelectOption = {
        name: menuItem.title,
        value: menuItem._doc,
        checked: false,
        disabled: false,
      };
      return option;
    });
  }, [menuItems]);

  return (
    <Consumer>
      {({ updatedArticle, onChangeArticle }) => {
        const selectedOption = menuItems.find(
          (menuItemTemp) => menuItemTemp._doc === updatedArticle.menuItemId
        );
        return (
          <>
            <p>Select menu item "tag"</p>
            <Select
              data={allOptions}
              selected={{
                value: selectedOption?._doc,
                name: selectedOption?.title,
                checked: false,
                disabled: false,
              }}
              onChange={(value: SelectOption) => {
                onChangeArticle({ menuItemId: value.value });
              }}
              elementId="menu-item-selector"
              placeholder="Select menu item"
            />
          </>
        );
      }}
    </Consumer>
  );
};

export default MenuItemSelect;
