import { HeroBannerSize } from 'api/cms/HeroBanner/types';
import { BannerLarge } from 'pagesNew/Home/Banner/BannerLarge';
import { BannerMedium } from 'pagesNew/Home/Banner/BannerMedium';
import { useFormContext } from 'react-hook-form';

import { FormValues } from './HeroBannerForm';

const HeroBannerPreview = () => {
  const methods = useFormContext<FormValues>();

  const { watch } = methods;
  const { size, title, description, linkUrl, linkLabel, heroImage } = watch();

  return size === HeroBannerSize.Medium ? (
    <BannerMedium
      id="hero-banner-preview-medium"
      title={title}
      description={description}
      linkText={linkLabel}
      linkUrl={linkUrl}
    />
  ) : (
    <BannerLarge
      id="hero-banner-preview-large"
      title={title}
      description={description}
      linkText={linkLabel}
      linkUrl={linkUrl}
      imageUrl={heroImage.raw?.file || heroImage.uploaded}
    />
  );
};

export { HeroBannerPreview };
